* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
}

@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}

.meeting_drop_down{
  width: 100% !important;
}

div#Dropdown4-list{
  background: #e9e9e9 !important;

}
  span#Dropdown4-option {
    background: #e9e9e9 !important;
  }

.childrenDiv{
  /* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(79, 82, 178);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
}
