.meetings-container {
  width: 100%;
}
.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.upload-container {
  display: flex;
  justify-content: right;
  padding-bottom: 10px;
}

.meeting-dropdown {
  min-width: 200px;
}

.summary-button {
  margin-left: 10px;
  background: #4F52B2;
  width: 200px;
}

.upload-button {
  background: #4F52B2;
  width: 100%;
}

.upload-button {
  /* margin-left: 10px; */
  background: #4F52B2;
  width: 170px;
}

.summary-button:hover {
  background-color: #4F52B2 !important;
}

.spinner {
  margin-top: 40px;
}

.loader-text{
  justify-content: center;
  display: flex;
  color: gray;
}

.meeting-details-main{
  width: 100%;
  overflow-y: auto !important;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(79, 82, 178);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.meeting-details {
  background: #e9e9e9 !important;
  height: 300px;
  width: 100%;
  overflow: auto !important;
}

.meeting-schedule-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  min-height: 620px;
}

.text {
  font-size: 22;
  margin-right: 10px
}

.subText{
  margin-top: 10px;
  font-size: 18;
}

.attendees {
  display: flex;
  flex-wrap: wrap;
  gap: 2px 6px;
  /* overflow-y: auto;
  max-height: 200px;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(79, 82, 178);
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
}

.avtar {
  margin: 5px;
}

.attendees-text {
  padding-left: 2px;
}

.card-main-div {
  display: flex;
  justify-content: center;
  /* justify-content: space-evenly; */
  gap: 20px;
  height: 100%;
  min-height: 620px;
}

.childDiv-card {
  width: 250px;
  ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(79, 82, 178);
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

.childrenDiv-summary {
    min-height: 100%;
    width: 750px;
}

.childrenDiv-action {
    margin-bottom: 100px;
    width: 250px;
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(79, 82, 178);
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

.childrenDiv-insight {
    position: absolute;
    bottom: 0;
    width: 250px;
    height: 48%;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(79, 82, 178);
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

.childrenDiv-agenda {
    position: absolute;
    bottom: 0;
    width: 250px;
    height: 48%;
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(79, 82, 178);
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
}

.meeting-card {
  background: #e9e9e9 !important;
  height: 48%;
  min-height: 48%;
  overflow: auto !important;
}

.agenda-card {
  background: #e9e9e9 !important;
  height: 100%;
  overflow: auto !important;
}

.list {
  color: black;
}

.ul-style {
  margin: 0 0 0 -15px;
}

.no-attendees {
  color: "#da3b01";
  font-weight: 700
}

.summary-card {
  min-height: 100%;
  height: 100%;
  background: #e9e9e9 !important;
  overflow: auto !important;
}

.action-card {
  background: #e9e9e9 !important;
  height: 48%;
  min-height: 48%;
  overflow: auto !important;
}

.insights-card {
  background: #e9e9e9 !important;
  height: 100%;
  overflow: auto !important;
}

.custom-file-input {
  border: 2px dashed rgb(79, 82, 178);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.custom-file-input input[type="file"] {
  opacity: 0;
  cursor: pointer;
}

/* Mobile screens */
@media (max-width: 768px) {
  .card-main-div {
    flex-direction: column;
    gap: 10px;
  }

  .childDiv-card,
  .childrenDiv-action,
  .childrenDiv-insight,
  .childrenDiv-agenda {
    width: 100%;
    position: static;
    margin-bottom: 20px;
  }

  .meeting-details {
      margin: 10px;
    }

    .summary-button {
      margin-top:10px;
      margin-left:0px;
      width: 100%;
      display:flex;
      justify-content:center
  }

  .upload-button {
    margin-top:10px;
    margin-left:0px;
    width: 100%;
    display:flex;
    justify-content:center
}

  .dropdown-container {
      display:block ;
      padding: 0 10px
    }

    .upload-container {
      margin-top: 10px;
      padding: 0 10px
    }

    .meeting-card, .agenda-card ,.action-card, .insights-card  {
      margin: 10px;
    }

    .childrenDiv-summary{
      width: calc(100% - 20px);
      margin: 10px;
    }

    .custom-file-input {
      width: 100%;
    }
}
